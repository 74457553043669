import React,{useEffect} from 'react';
import Header from '../../../components/Header';
import './style.css'
import {Link} from 'react-router-dom';
import Footer from '../../../components/Footer';

function Blog_HeroSection(){
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])
    return(
        <>
            <div className='Blog_HeroSection'>
                <div className="container py-5">
                    <h1 className=' py-5' style={{textAlign: 'center'}}>LATEST BLOGS</h1>

                    <div className="row">
                        <div className="col-md">
                            <img src="https://wodo.digital/wp-content/uploads/2024/04/Marketing-on-a-Budget-wodo.digital.png" style={{maxWidth:'100%'}} alt="" />
                        </div>
                        <div className="col-md">
                            <div style={{display:'flex',flexDirection:'row',gap:'14px'}}>
                                <div>
                                    <span><i class="fas fa-calendar"></i> April 20, 2024</span>
                                </div>

                                <div>
                                    <span><i class="far fa-clock"></i> 12:13 pm</span>
                                </div>

                                <div>
                                    <span><i class="fas fa-tags"></i> Marketing Guide</span>
                                </div>
                            </div>

                            <h1 class="py-2">Marketing on a Budget: Effective Strategies for Startups and Small Businesses</h1>
                            <p>Having a brilliant product or service is just the first step. For startups and small businesses, limited resources often present a challenge when it comes....</p>

                            <Link className='readmore_btn' to='/marketing-on-a-budget-effective-strategies-for-startups-and-small-businesses/'>Read More...</Link>

                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

function Blog_Items(){
    return(
        <>
            <div class="grid-blog-item" >
                <div style={{position:'relative'}}>
                    <div>
                        <img style={{maxWidth:'100%'}} src="https://wodo.digital/wp-content/uploads/2024/04/Marketing-on-a-Budget-wodo.digital.png" alt="Digital Marketing Agency For Startups"/>
                        <span className='post_badge'>Digital Dairy</span>
                    </div>

                    <Link className='post_title' to='/marketing-on-a-budget-effective-strategies-for-startups-and-small-businesses/'>
                        Marketing on a Budget: Effective Strategies for Startups and Small Businesses
                    </Link>

                    <p className='post_content'>Having a brilliant product or service is just the first step. For startups and small businesses, limited resources often present a challenge when it comes</p>

                    <Link className='readmore_btn' to='/marketing-on-a-budget-effective-strategies-for-startups-and-small-businesses/'>Read More...</Link>

                </div>
            </div>
        </>
    );
}

function List_Of_Blogs(){
    return(
        <>
            <div className="container">
                <div class="grid-blog-container py-5">
                    <Blog_Items/>
                    <Blog_Items/>
                    <Blog_Items/>
                    <Blog_Items/>
                    <Blog_Items/>
                    <Blog_Items/>
                    <Blog_Items/>
                    <Blog_Items/>
                    <Blog_Items/>
                    <Blog_Items/>
                    <Blog_Items/>
                    <Blog_Items/>
                </div>
            </div>
        </>
    );
}


function Blog_Index() {
  return (
    <>
        <Header/>
        <Blog_HeroSection/>
        <List_Of_Blogs/>
        <Footer/>

    </>
  )
}

export default Blog_Index