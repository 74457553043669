import React from 'react';
import './CarouselCard.css'
import icon from '../../../media/right-arrow.svg'

const CarouselCard = ({ title, description, url, backgroundImage }) => {
  const cardStyle = {
    backgroundImage: `url("https://wodo.digital/wp-content/uploads/2023/12/Website-Mockup-PSD-Files-Final.jpg")`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    height: '400px',
    color: 'white',
    borderRadius: '15px',
  };

  return (
    <div className="card mx-2 border-0">
      <div className="card-body" style={cardStyle}>
        <div className="card-content">
        <h5 className="card-title">{title}</h5>
        <p className="card-text">{description}</p>
        <a href={url} className="link-icon">
            <img src={icon} alt="Link Icon" width="25" />
          </a>
        </div>
      </div>
    </div>
  );
};

export default CarouselCard;
