import React from 'react';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import CarouselCard from './CarouselCard';


const data = [
  {
    title: 'Certefy',
    description: 'At Certefy, we are not just an ed-tech company; we are a guiding force for graduates and working professionals.',
    url: 'https://google.com',
  },
  {
    title: 'Hasiruagro',
    description: 'At Certefy, we are not just an ed-tech company; we are a guiding force for graduates and working professionals.',
    url: 'https://google.com',
  },
  {
    title: 'E-Construct',
    description: 'At Certefy, we are not just an ed-tech company; we are a guiding force for graduates and working professionals.',
    url: 'https://google.com',
  },
  {
    title: 'Visago',
    description: 'At Certefy, we are not just an ed-tech company; we are a guiding force for graduates and working professionals.',
    url: 'https://google.com',
  },
  {
    title: 'Hombale Films',
    description: 'At Certefy, we are not just an ed-tech company; we are a guiding force for graduates and working professionals.',
    url: 'https://google.com',
  },
  {
    title: 'Rightcon',
    description: 'At Certefy, we are not just an ed-tech company; we are a guiding force for graduates and working professionals.',
    url: 'https://google.com',
  }
];

function Carousel_Slider() {

    var settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        initialSlide: 0,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 3,
              infinite: true,
              dots: true
            }
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              initialSlide: 2
            }
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1
            }
          }
        ]
      };
  return (
    <>
      <div className="container py-4">
        <div className="row">
        <Slider {...settings}>
          {data.map((item, index) => (
            <CarouselCard
            key={index}
            title={item.title}
            description={item.description}
            url={item.url}
            backgroundImage={item.backgroundImage}
          />
          ))}
          </Slider>
        </div>
      </div>
    </>
  );
}

export default Carousel_Slider;
