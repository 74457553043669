import React from 'react';
import './style.css';

function CallToAction() {
  return (
    <>
      <div className="background_image py-5 verticle_center">
        <div className="container">
          <h1 style={{color:'#FFF',textAlign:'center'}} className='gf_h3' >INDIA PAVILION: DRIVING THE FUTURE OF ELECTRIC MOBILITY</h1>
          <br />
          <br />
          <img src="https://wodo.digital/wp-content/uploads/2023/12/India-Flag.webp" style={{width:'100%'}} alt="" />

          <p className='gf_cta_paragraph'>Sculpt the e-Mobility future with India Pavilion, your gateway to international prominence. Co-craft a narrative that resonates uniquely with your vision.</p>
        </div>
      </div>
    </>

  )
}

export default CallToAction