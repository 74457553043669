import React from 'react'
import Logo from '../media/wodo-logo.png'
import Menu from '../media/menu-Icon.png';
import './header_css.css'
import {Link} from 'react-router-dom'

function Header() {
  return (
    <div className='header_container' style={{zIndex:99}}>
            <nav className="navbar ">
                <div className="container">
                    <Link className="navbar-brand" to="/">
                        <img src={Logo} className='header_logo' title="Digital Marketing Agency For Startups"/>
                    </Link>
                    <span className="navbar-toggler" style={{border:'none'}} type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasNavbar" aria-controls="offcanvasNavbar" aria-label="Toggle navigation">
                        {/* <span className="navbar-toggler-icon"></span> */}
                        <img src={Menu} alt="" style={{height:'42px'}} />
                    </span>
                    <div className="offcanvas offcanvas-end" tabIndex="-1"  id="offcanvasNavbar" aria-labelledby="offcanvasNavbarLabel">
                    <div className="offcanvas-header">
                        <h5 className="offcanvas-title" id="offcanvasNavbarLabel">Offcanvas</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                    </div>
                    <div className="offcanvas-body">
                        <ul className="navbar-nav justify-content-end flex-grow-1 pe-3">
                        <li className="nav-item">
                            <a className="nav-link active" aria-current="page" href="#">Home</a>
                        </li>
                        <li className="nav-item">
                            {/* <a className="nav-link" href="#">Link</a> */}
                            <Link className="nav-link" to="/about-us">About Us</Link>
                        </li>
                        <li className="nav-item dropdown">
                            <a className="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                            Dropdown
                            </a>
                            <ul className="dropdown-menu">
                            <li><a className="dropdown-item" href="#">Action</a></li>
                            <li><a className="dropdown-item" href="#">Another action</a></li>
                            <li>
                                <hr className="dropdown-divider"/>
                            </li>
                            <li><a className="dropdown-item" href="#">Something else here</a></li>
                            </ul>
                        </li>
                        </ul>
                        <form className="d-flex mt-3" role="search">
                        <input className="form-control me-2" type="search" placeholder="Search" aria-label="Search"/>
                        <button className="btn btn-outline-success" type="submit">Search</button>
                        </form>
                    </div>
                    </div>
                </div>
                </nav>
            {/* <nav className="navbar navbar-expand-lg">
                <div className="container-fluid">
                    <a className="navbar-brand" href="/">
                        <img src={Logo} className='header_logo' title="Digital Marketing Agency For Startups"/>
                    </a>
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul className="navbar-nav me-auto mb-2 mb-lg-0">

                    </ul>
                    <span className="d-flex" role="search">
                        
                        <img src={Menu} alt="" style={{height:'42px'}} />
                    </span>
                    </div>
                </div>
            </nav> */}
        
    </div>
  )
}

export default Header
