import React,{useEffect} from 'react'
import './About_css.css'
import Header from '../../components/Header'
import Footer from '../../components/Footer';
import Awards from '../Home/Awards/Awards';


//Hero Section

function HeroSectionSlider(){

    return(
        <>
        <div className="fluid-container vh-90 hero-about">      
            <h2 className="gs_h2">THE <br/>WODOVERSE</h2> 
        </div>
        </>
    )

}
//Hero Section

// Team Section

function TeamSection(){

    return(
        <>

        <div className="fluid-container section">
        <h2 className="gs_h2 h2_black">OUR <br/>CREATEAM</h2> 
        <p>Ever seen an idea get sketched on a napkin and then built into a digital empire? That's just another Tuesday at WODO, where our tribe of creative minds and innovative hands turns daydreams into reality.</p>
        <p><b>P.S.</b> We have a resident office dog who dispenses cuddles and productivity boosts in equal measure. Just saying</p>

        </div>
        
        </>



    );

}

// Team Section
function AboutUs() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])
    
  return (
  <>
    <Header/>
    <HeroSectionSlider/>
    <TeamSection/> 
    <Awards/>
    <Footer/> 
  </>
  )
}

export default AboutUs