import React from 'react'
import Logo from '../media/wodo-logo.png'
import './header_css.css'
import {Link} from 'react-router-dom'

function Footer() {
    
  return (
    <>
    <div className="bg-dark py-5">
    <div className="container">
            <div className="row">
                <div className="col-md-4 text-left">
                    <img src={Logo} alt="" className="header_logo" />
                    <p className="text-light py-2">Forget templates, we paint your brand with vibrant colors and voice that stands out. Prepare to be seen, heard, and remembered.​</p>
                </div>
                <div className="col-md-4 text-start text-light pt-4 footer_align_list">
                    <ul style={{ listStyle: "none" }}>
                        <li><Link to='/about-us' style={{textDecoration:'inherit',color:'#fff'}}>About Us</Link></li>
                        <li>Case Studies</li>
                        <li>Join Wodo</li>
                        <li><Link to='/blog' style={{textDecoration:'inherit',color:'#fff'}}>Blog</Link></li>
                    </ul>
                </div>
                <div className="col-md-4 text-start text-light pt-4 footer_align_list">
                    <div>
                        <ul style={{ listStyle: "none" }}>
                            <li>hello@wodo.digital</li>
                            <li><b>India</b><br/>+91 80889 98516</li>
                            <li><b>USA</b><br/>+1 (980) 335-7724</li>
                            <li><b>UAE</b><br/>+971 50 813 5828</li>
                        </ul>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-2">
                    
                </div>
                <div className="col-8">
                    
                </div>
                <div className="col-2">
                    
                </div>
            </div>
        </div>
    </div>
    </>
  )
}

export default Footer
