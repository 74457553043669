import React from 'react'
import './style.css'
import ElementorButton from '../../../components/elementorButton/elementorButton';
import Carousel_Slider from './Carousel_Slider';



function CaseStudies() {
  return ( 
    <>
      <div className="container py-5 " style={{position:'relative',zIndex:10}}>
        <div style={{display: 'flex',position:'relative'}}>
          <div style={{width:'60px',backgroundColor:'#1f1f1f',height:5,marginTop:5, marginRight:10,position:'absolute',left:'-65px'}}>
              {/* dash */}
          </div>

          <div>
              <div className="Heading_title-black">
                SUCCESS
                <br />
                STORIES
              </div>
              <p style={{fontSize:'14px',lineHeight:'1.5em', width:'65%'}}>
                The stories behind the stats—buckle up for a joyride through our case studies where real brands find their digital groove.
              </p>


          </div>
        </div>

        <Carousel_Slider/>

        <ElementorButton mode='light' text='TAKE A PEEK!' URL='https://wodo.digital/projects/'/>

      </div>
    </>
  )
}

export default CaseStudies;
