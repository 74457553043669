import React from 'react'
import AwardsBadge from '../../../media/Elementor-Website-Awards-2021.png';
import { motion,useScroll, useTransform } from "framer-motion";
import { Title,AnimationBody } from './style';

function Awards() {
  
  const { scrollYProgress } = useScroll();
  const x1 = useTransform(scrollYProgress, [0, 1], [470,-10]);
  const x2 = useTransform(scrollYProgress, [0, 1], [-600, 470]);
  
  return (
    <>
      <div className='awardsBackground'>


        <div className="container">
          <div style={{display: 'flex',width:'100%',position:'relative'}}>
            <div style={{width:'60px',backgroundColor:'#fff',height:5,marginTop:5, marginRight:10,position:'absolute',left:'-65px'}}>
              {/* dash */}
            </div>

            <div>
              <div className="Heading_title">
                WODO
                <br />
                SPOTLIGHT
              </div>
              <p style={{fontSize:'14px',lineHeight:'1.5em'}}>Revealing our creative conquests that keep us toe-tapping on the edge!</p>

            </div>
          </div>

          <div className="row py-5">
            <div className="col verticle_center centerElements" style={{textAlign:'right',alignItems:'end'}}>
              <span style={{fontWeight:100,fontSize:'44px',lineHeight:1}}>
                Award Winning
                <br />
                Digital Agency
                </span>
                <br />
                <br />
                <span style={{color: '#F8A925',fontSize: '44px',fontWeight: 600,lineHeight:1}}>
                ELEMENTOR
                <br />
                WEBSITE 2021
                </span>
              </div>
                <div className="col-1 verticle_center ">
                  <div className='hideElementInPhone' style={{width:3,backgroundColor:'#fff',height:120}}>
                    {/* dash */}
                  </div>
                </div>
                <div className="col centerElements" style={{textAlign:'left'}}>
                  <div style={{position:'relative',textAlign:'center'}}>
                    <img style={{position:'absolute',top:'50%',left:'50%',transform:'translate(-50%,-50%)',width:'120px'}} src="https://wodo.digital/wp-content/uploads/2022/04/Group-680@2x.png" alt="" />
                    <div className="awards_circle">
                      <img width="265" height="267" src={AwardsBadge} alt="Digital Marketing Agency For Startups" title="Digital Marketing Agency For Startups" />
                    </div>
                  </div>

                </div>
              </div>
        </div>



        <div className='Heading_title'>
          <AnimationBody>
            <Title style={{ x:x1 }}>UI/UX <span className='wodo_yellow'>•</span> LOGOS <span className='wodo_yellow'>•</span> WEBSITES <span className='wodo_yellow'>•</span> BRAND STRATEGY <span className='wodo_yellow'>•</span> CREATIVE CONTENT</Title>

            {/* <motion.h1 style={{color: 'white',fontSize: '1em',zIndex: 1,margin: 0,whiteSpace: 'nowrap',marginTop: 'auto',transition: 'all 0.5s infinity', x2}}>Consultation <span className='wodo_yellow'>•</span> Growth Marketing <span className='wodo_yellow'>•</span> Conversion Optimization</motion.h1> */}
            <Title style={{ x:x2}}>Consultation <span className='wodo_yellow'>•</span> Growth Marketing <span className='wodo_yellow'>•</span> Conversion Optimization</Title>
        
          </AnimationBody>

        </div>

      </div>

    </>
  )
}

export default Awards