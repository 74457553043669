import React,{useEffect} from 'react'
import Header from '../../components/Header'
import Footer from '../../components/Footer';
import '../../css/App.css'
import '../../css/index.css' 


import HeroSection from './HeroSection/HeroSection';
import ProjectTabs from './ProjectTabs/ProjectTabs';
import Services from './Services/Services';
import Awards from './Awards/Awards';
import Clients from './Clients/Clients';
import CaseStudies from './CaseStudies/CaseStudies';
import CallToAction from './CallToAction/CallToAction'
import FloatingButton from '../../components/FloatingButton/FloatingButton';




const Home=()=>{
  // useEffect(() => {
  //   window.scrollTo(0, 0);
  // }, [])
  
  return (
    <>
      <FloatingButton/>
      <Header/> 
      <HeroSection/>
      <ProjectTabs/>  {/* images width issue */}
      <Services/>  {/* slider issue */}
      <Awards/> 
      <Clients/>
      <CaseStudies/>
      <CallToAction/>
      <Footer/>
    </>
  )
}

export default Home