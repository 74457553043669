import React from 'react'
import './style.css'
import { Link } from 'react-router-dom'

function Client_block(props){
  return(
    <>
      <div class="grid-item verticle_center">
        <Link to={props.URL}>
          <img className='client_logo' src={props.logo} alt="" />
        </Link>
      </div>
    </>
  );
}


function Clients() {
  return (
    <>
      <div className=''>
        <div className="container py-5 " style={{position:'relative',zIndex:10}}>

          <div style={{display: 'flex',position:'relative'}}>
              <div style={{width:'60px',backgroundColor:'#1f1f1f',height:5,marginTop:5, marginRight:10,position:'absolute',left:'-65px'}}>
                  {/* dash */}
              </div>

              <div>
                  <div className="Heading_title-black">
                      
                      PARTNER

                      <br />
                      PARADE 
                  </div>
                  <p style={{fontSize:'14px',lineHeight:'1.5em', width:'65%'}}>
                    Companies who picked us for their digital journey.
                  </p>
              </div>
            </div>


            <div class="grid-container">
              <div class="grid-item verticle_center">
                <h3 className='gf_h1' style={{color:'#1f1f1f'}}>180</h3>
                Clients
              </div>
              <div class="grid-item verticle_center">
                <h3 className='gf_h1' style={{color:'#1f1f1f'}}>6</h3>
                Countries
              </div>

              <Client_block logo='https://wodo.digital/wp-content/uploads/2021/10/Hombale-Films-Logo-150x150.png' URL='https://wodo.digital/projects/hombale-films/'/>

              <Client_block logo='https://wodo.digital/wp-content/uploads/2023/04/WODO-Clients-Gandhadagudi-1.png' URL=''/>
              <Client_block logo='https://wodo.digital/wp-content/uploads/2024/04/PRK-logo.png' URL=''/>

            </div>

          </div>
        </div>

    </>

  )
}

export default Clients