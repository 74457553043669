import React from 'react'
import {Link} from 'react-router-dom'
import './style.css'
function FloatingButton() {
  return (
    <>
        <Link to="/contact" className='FloatingButton'>
            <img src="https://wodo.digital/wp-content/uploads/2022/03/WODO-Contact-Circle-Black.png" alt="Best Digital marketing agency in Bengaluru" />
        </Link>
    </>
  )
}

export default FloatingButton