import React from 'react';
import ElementorButton from '../../../components/elementorButton/elementorButton';
import './style.css';

function SliderCompany(){
  return(
    <>
      <div className="slider ">
        <div className="slide-track">
          <div className="slide verticle_center_company">
            <img src="https://wodo.digital/wp-content/uploads/2022/06/google-logo-web-1.png"  width="250" alt="" />
          </div>
          <div className="slide verticle_center_company">
            <img src="https://wodo.digital/wp-content/uploads/2022/06/godaddy-logo-2-1.png"  width="250" alt="" />
          </div>
          <div className="slide verticle_center_company">
            <img src="https://wodo.digital/wp-content/uploads/2022/06/Meta-Logo-1024x576-1.png"  width="250" alt="" />
          </div>
          <div className="slide verticle_center_company">
            <img src="https://wodo.digital/wp-content/uploads/2022/06/mailchimp-logo-black-png-transparent-860x239-1.png"  width="250" alt="" />
          </div>
          <div className="slide verticle_center_company">
            <img src="https://wodo.digital/wp-content/uploads/2022/06/2560px-Hostinger_logo_purple.svg-1.png"  width="250" alt="" />
          </div>
          <div className="slide verticle_center_company">
            <img src="https://wodo.digital/wp-content/uploads/2022/06/elementor-logo.png"  width="250" alt="" />
          </div>
        </div>
      </div>
    </>
  );
}





function Services() {
  return (
    <>
      <div className="container">
        <div style={{display: 'flex',width:'100%',position:'relative'}}>
          <div style={{width:'60px',backgroundColor:'#1f1f1f',height:5,marginTop:5, marginRight:10,position:'absolute',left:'-65px'}}>
            {/* dash */}
          </div>

          <div>
            <div className="Heading_title-black">
              BREATHING LIFE INTO
              <br />
              DIGITAL IDEAS!
            </div>
            <p style={{fontSize:'14px',lineHeight:'1.5em', width:'65%'}}>
              Your brand deserves an online presence that tells its story and connects with your audience. WODO crafts custom digital experiences that engage, inspire, and ultimately drive conversions. Let’s build something remarkable together.
            </p>

          </div>
        </div>

        <div className="row" >
          <div className="col-md-4">
            <img src="https://wodo.digital/wp-content/uploads/2022/03/ezgif.com-gif-maker-3.gif" alt="" />
            <h3>Growth Galore,<br /> No Boredom</h3>
            <p>Spice up your strategy with growth-driven genius. We don't do quick fixes; we're the strategic seasoning that makes your business a long-lasting success.</p>
            <br />
            <ElementorButton mode='light' URL='/' text='CASE CRACKER'/>
          </div>
          <div className="col-md-4 p-3">
            <img src="https://wodo.digital/wp-content/uploads/2022/03/ezgif.com-gif-maker-4.gif" alt="" />
            <h3>Tech Solutions</h3>
            <p>Need it all? WODO's got you covered - from domain debut to online stardom, we're the tech wizards making your journey seamless, magical, and utterly delightful. (AI to Saas, Digital campaigns)</p>
            <br />
            <ElementorButton mode='light' URL='/' text='TAP TO VIEW'/>
          </div> 
          <div className="col-md-4">
            <img src="https://wodo.digital/wp-content/uploads/2022/03/ezgif.com-gif-maker-5.gif" alt="" />
            <h3>Hotline Bling, <br />Not Silent Ring</h3>
            <p>Our customer service isn't a cold shoulder. We're the hotline you can't resist. Got questions? We've got answers - served up.</p>
            <br />
            <ElementorButton mode='light' URL='/' text="Let's talk solution"/>
          </div>
        </div>

        <div style={{display: 'flex',width:'100%',position:'relative', marginTop:'100px', marginBottom:'100px'}}>
          <div style={{width:'60px',backgroundColor:'#1f1f1f',height:5,marginTop:5, marginRight:10,position:'absolute',left:'-65px'}}>
            {/* dash */}
          </div>

          <div style={{display: 'flex',width:'100%',position:'relative'}}>
            <div className="Heading_title-black me-5">
              DIGITAL
              <br />
              PARTNERS
            </div>

            <SliderCompany/>

            {/* <p style={{fontSize:'14px',lineHeight:'1.5em', width:'65%'}}>
            Your brand deserves an online presence that tells its story and connects with your audience. WODO crafts custom digital experiences that engage, inspire, and ultimately drive conversions. Let’s build something remarkable together.
            </p> */}
          </div>
        </div>



      </div>
    </>
  )
}

export default Services