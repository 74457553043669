import { 
  BrowserRouter as Router,
  Route,
  Routes
} from 'react-router-dom'
import Home from './app/Home/Home';
import AboutUs from './app/AboutUs/AboutUs';
import Blog_Index from './app/Blog/MainPage/Blog_Index';
import BlogDetails from './app/Blog/DetailsPage/BlogDetails';

function App() {
  return (
    <>
      <Router>
        <Routes>
          <Route path='/' element={<Home/>}/>
          <Route path='/about-us' element={<AboutUs/>}/>
          <Route path='/blog' element={<Blog_Index/>}/>
          <Route path='/:id' element={<BlogDetails/>}/>
        </Routes>
      </Router>
    </>
  );
}

export default App;
